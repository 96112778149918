// import 'theme/semantic.less';
import 'theme/calculator.less';
import 'theme/react-viz.scss';

import { Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';
import { hot } from 'react-hot-loader';

import Calculator from './screens/Calculator';

const App = (data) => (
  <Switch>
    <Route render={(props) => <Calculator {...props} appConfig={data.appConfig} />} />
  </Switch>
);

export default hot(module)(App);
