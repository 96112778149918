import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  HorizontalGridLines,
  XAxis,
  YAxis,
  VerticalBarSeries,
  DiscreteColorLegend,
  FlexibleWidthXYPlot, AreaSeries, LineMarkSeries, LineSeries
} from 'react-vis';
import { Radio } from 'semantic-ui-react';


@inject('config', 'years')
export default class EbitdaGraph extends React.Component {
  constructor(props) {
    super(props);
    this.graphConfig = {
      colors: {
        'color_profit': '#4472c4',
        'color_interest': '#a5a5a5',
        'color_taxes': '#a361c7',
        'color_deprecation': '#5b9bd5',
        'color_amortization': '#264478',
        'color_addback': '#FF8C00'
      },
    };
  }

  state = {
    barsEnabled: this.props.config.isBarsEnabled()
  }

  switchBarType (checked) {
    this.setState(
      {
        ...this.state,
        barsEnabled: checked
      }
    );
    this.props.config.setBarsEnabled(checked);
  }

  render() {
    const { colors } = this.graphConfig;
    const { config } = this.props;
    const { barsEnabled } = this.state;
    const { year1values, year2values, year3values, addbackValues } = this.props.years.values;

    const titleYear1 = config.getMessage('graph_line_x_year_1');
    const titleYear2 = config.getMessage('graph_line_x_year_2');
    const titleYear3 = config.getMessage('graph_line_x_year_3');

    const ChartElement = barsEnabled ? VerticalBarSeries : AreaSeries;
    if (config.getMessage('calculation_type') === 1) { // one year

      return (
        <div className="ebitda-graph-container">
          <h3 className="graph-title">Adjusted EBITDA<span className="graph-type-toggle">
          </span></h3>
          <FlexibleWidthXYPlot height={160} stackBy="y" xType="ordinal" className="ebitda-graph" margin={{ left: 60, top: 15, bottom: 25 }}>
            <DiscreteColorLegend
              items={[
                { 'title': config.getMessage('graph_legend_profit'), 'color': colors.color_profit },
                { 'title': config.getMessage('graph_legend_interest'), 'color': colors.color_interest },
                // { 'title': config.getMessage('graph_legend_taxes'), 'color': colors.color_taxes },
                { 'title': config.getMessage('graph_legend_deprecation'), 'color': colors.color_deprecation },
                { 'title': config.getMessage('graph_legend_amortization'), 'color': colors.color_amortization },
                { 'title': config.getMessage('graph_addback'), 'color': colors.color_addback },
              ]}
            />
            <HorizontalGridLines/>
            <XAxis/>
            <YAxis tickFormat={value => config.formatNumberValue(value, true)}/>
            <ChartElement color={colors.color_profit} data={[
              { x: titleYear1, y: (year1values.pre_tax_profit_year || 0) * 1, y0: 0 },
            ]}/>
            <ChartElement color={colors.color_interest} data={[
              { x: titleYear1, y: (year1values.interest_year || 0) * 1, y0: 0 },
            ]}/>
            {/*<ChartElement color={colors.color_taxes} data={[*/}
            {/*  { x: titleYear1, y: (year1values.corporate_income_tax_year || 0) * 1, y0: 0 },*/}
            {/*]}/>*/}
            <ChartElement color={colors.color_deprecation} data={[
              { x: titleYear1, y: (year1values.depreciation_year || 0) * 1, y0: 0 },
            ]}/>
            <ChartElement color={colors.color_amortization} data={[
              { x: titleYear1, y: (year1values.amortization_year || 0) * 1, y0: 0 },
            ]}/>
            <ChartElement color={colors.color_addback} data={[
              { x: titleYear1, y: (config.getAddbackTotalValue(addbackValues) || 0) * 1, y0: 0 },
            ]}/>
          </FlexibleWidthXYPlot>
        </div>
      )

    } else {
      return (
        <div className="ebitda-graph-container">
          <h3 className="graph-title">EBITDA<span className="graph-type-toggle">
          <Radio
            toggle
            label="bars"
            onChange={(e, { checked }) => this.switchBarType(checked)}
            checked={barsEnabled}
          /></span></h3>
          <FlexibleWidthXYPlot height={160} stackBy="y" xType="ordinal" className="ebitda-graph" margin={{ left: 60, top: 15, bottom: 25 }}>
            <DiscreteColorLegend
              items={[
                { 'title': config.getMessage('graph_legend_profit'), 'color': colors.color_profit },
                { 'title': config.getMessage('graph_legend_interest'), 'color': colors.color_interest },
                // { 'title': config.getMessage('graph_legend_taxes'), 'color': colors.color_taxes },
                { 'title': config.getMessage('graph_legend_deprecation'), 'color': colors.color_deprecation },
                { 'title': config.getMessage('graph_legend_amortization'), 'color': colors.color_amortization },
              ]}
            />
            <HorizontalGridLines/>
            <XAxis/>
            <YAxis tickFormat={value => config.formatNumberValue(value, true)}/>
            <ChartElement color={colors.color_profit} data={[
              { x: titleYear1, y: (year1values.pre_tax_profit_year || 0) * 1, y0: 0 },
              { x: titleYear2, y: (year2values.pre_tax_profit_year || 0) * 1, y0: 0 },
              { x: titleYear3, y: (year3values.pre_tax_profit_year || 0) * 1, y0: 0 }
            ]}/>
            <ChartElement color={colors.color_interest} data={[
              { x: titleYear1, y: (year1values.interest_year || 0) * 1, y0: 0 },
              { x: titleYear2, y: (year2values.interest_year || 0) * 1, y0: 0 },
              { x: titleYear3, y: (year3values.interest_year || 0) * 1, y0: 0 }
            ]}/>
            {/*<ChartElement color={colors.color_taxes} data={[*/}
            {/*  { x: titleYear1, y: (year1values.corporate_income_tax_year || 0) * 1, y0: 0 },*/}
            {/*  { x: titleYear2, y: (year2values.corporate_income_tax_year || 0) * 1, y0: 0 },*/}
            {/*  { x: titleYear3, y: (year3values.corporate_income_tax_year || 0) * 1, y0: 0 }*/}
            {/*]}/>*/}
            <ChartElement color={colors.color_deprecation} data={[
              { x: titleYear1, y: (year1values.depreciation_year || 0) * 1, y0: 0 },
              { x: titleYear2, y: (year2values.depreciation_year || 0) * 1, y0: 0 },
              { x: titleYear3, y: (year3values.depreciation_year || 0) * 1, y0: 0 }
            ]}/>
            <ChartElement color={colors.color_amortization} data={[
              { x: titleYear1, y: (year1values.amortization_year || 0) * 1, y0: 0 },
              { x: titleYear2, y: (year2values.amortization_year || 0) * 1, y0: 0 },
              { x: titleYear3, y: (year3values.amortization_year || 0) * 1, y0: 0 }
            ]}/>
          </FlexibleWidthXYPlot>
        </div>
      )
    }
  }
}