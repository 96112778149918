import React from 'react';
import {Form} from 'semantic-ui-react';
import {inject} from "mobx-react";
import Currency from "./form-fields/Currency";

@inject('config')
export default class AddbackForm extends React.Component {
  constructor(props) {
    super(props);
    this.formValues = {};
    this.setField = this.setField.bind(this);
    this.prefix = this.props.config.currency.prefix;
  }

  setFormat (values) {
    return this.prefix + parseFloat(values);
  }

  setField(name, values) {
    this.formValues = {
      ...this.props.formValues,
      [name]: values.value
    };

    this.props.onEbitdaFormChange(this.formValues);
  }

  getFieldLabel(fieldName) {
    const { config } = this.props;
    switch (fieldName) {
      default:
        return config.getMessage(fieldName + '_label');
    }
  }

  render() {
    const { formValues, config } = this.props;
    const { currency } = config;

    return (
      <Form>
        <Currency
          currency={currency}
          value={formValues.addback_real_estate || ''}
          name="addback_real_estate"
          label={ this.getFieldLabel('addback_real_estate') }
          type="text"
          onValueChange={(values) => this.setField("addback_real_estate", values)}
          format={(values) => this.setFormat(values)}
        />
        <Currency
          currency={currency}
          value={formValues.addback_transportation || ''}
          name="addback_transportation"
          label={this.getFieldLabel('addback_transportation')}
          type="text"
          onValueChange={(values) => this.setField("addback_transportation", values)}
          format={(values) => this.setFormat(values)}
        />
        <Currency
          currency={currency}
          value={formValues.addback_personal_travel || ''}
          name="addback_personal_travel"
          label={this.getFieldLabel('addback_personal_travel')}
          type="text"
          onValueChange={(values) => this.setField("addback_personal_travel", values)}
          format={(values) => this.setFormat(values)}
        />
        <Currency
          currency={currency}
          value={formValues.addback_excess_bonuses || ''}
          name="addback_excess_bonuses"
          label={this.getFieldLabel('addback_excess_bonuses')}
          type="text"
          onValueChange={(values) => this.setField("addback_excess_bonuses", values)}
          format={(values) => this.setFormat(values)}
        />
        <Currency
          currency={currency}
          value={formValues.addback_other_benefit || ''}
          name="addback_other_benefit"
          label={this.getFieldLabel('addback_other_benefit')}
          type="text"
          onValueChange={(values) => this.setField("addback_other_benefit", values)}
          format={(values) => this.setFormat(values)}
        />
      </Form>
    )
  }
}