import React from 'react';
import {Form} from 'semantic-ui-react';
import {inject} from "mobx-react";
import Currency from "./form-fields/Currency";


@inject('config')
export default class EbitdaForm extends React.Component {
  constructor(props) {
    super(props);
    this.formValues = {};
    this.setField = this.setField.bind(this);
    this.prefix = this.props.config.currency.prefix;
  }

  setFormat (values) {
    return this.prefix + parseFloat(values);
  }

  setField(name, values) {
    this.formValues = {
      ...this.props.formValues,
      [name]: values.value
    };

    this.props.onEbitdaFormChange(this.formValues);
  }

  getFieldLabel(fieldName) {
    const { yearNumber, config } = this.props;
    switch (fieldName) {
      case 'pre_tax_profit_year':
        switch (yearNumber) {
          case 1:
            return config.getMessage('year_1_pre_tax_profit_year_label');
          case 2:
            return config.getMessage('year_2_pre_tax_profit_year_label');
          case 3:
            return config.getMessage('year_3_pre_tax_profit_year_label');
        }
    }
  }

  render() {
    const { formValues, config } = this.props;
    const { currency } = config;

    return (
      <Form>
        <Currency
          currency={currency}
          value={formValues.pre_tax_profit_year || ''}
          name="pre_tax_profit_year"
          label={ this.getFieldLabel('pre_tax_profit_year') }
          type="text"
          onValueChange={(values) => this.setField("pre_tax_profit_year", values)}
          format={(values) => this.setFormat(values)}
        />
        <Currency
          currency={currency}
          value={formValues.interest_year || ''}
          name="interest_year"
          label={config.getMessage('interest_year_label')}
          type="text"
          onValueChange={(values) => this.setField("interest_year", values)}
          format={(values) => this.setFormat(values)}
        />
        {/*<Currency*/}
        {/*  currency={currency}*/}
        {/*  value={formValues.corporate_income_tax_year || ''}*/}
        {/*  name="corporate_income_tax_year"*/}
        {/*  label={config.getMessage('corporate_income_tax_year_label')}*/}
        {/*  type="text"*/}
        {/*  onValueChange={(values) => this.setField("corporate_income_tax_year", values)}*/}
        {/*  format={(values) => this.setFormat(values)}*/}
        {/*/>*/}
        <Currency
          currency={currency}
          value={formValues.depreciation_year || ''}
          name="depreciation_year"
          label={config.getMessage('depreciation_year_label')}
          type="text"
          onValueChange={(values) => this.setField("depreciation_year", values)}
          format={(values) => this.setFormat(values)}
        />
        <Currency
          currency={currency}
          value={formValues.amortization_year || ''}
          name="amortization_year"
          label={config.getMessage('amortization_year_label')}
          type="text"
          onValueChange={(values) => this.setField("amortization_year", values)}
          format={(values) => this.setFormat(values)}
        />
      </Form>
    )
  }
}