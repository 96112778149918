import React from 'react';
import { observer } from 'mobx-react';
import { Container } from 'semantic-ui-react';

@observer
export default class AppWrapper extends React.Component {
  render() {
    return (
      <div>
        <Container>
          {this.props.children}
        </Container>
      </div>
    );
  }
}
