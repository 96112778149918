import React from 'react';
import { Input, Form, Label } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';

export default function Currency({ label, required, value, currency, ...props }) {
  return (
    <Form.Field required={required}>
      <label>{label}</label>
      <NumberFormat
        value={value}
        displayType={'input'}
        thousandSeparator={currency.thousandSeparator}
        decimalSeparator={currency.decimalSeparator}
        decimalScale={currency.decimalScale}
        thousandsGroupStyle={currency.thousandsGroupStyle}
        allowNegative={currency.allowNegative}
        prefix={currency.prefix}
        {...props}
      />
    </Form.Field>
  );
}
