import { observable, action } from 'mobx';
import BaseStore from 'stores/BaseStore';

export default class Years extends BaseStore {
  defaultValues = {
    pre_tax_profit_year: '0',
    interest_year: '0',
    corporate_income_tax_year: '0',
    depreciation_year: '0',
    amortization_year: '0',
  };

  addbackDefaultValues = {
    addback_real_estate: '0',
    addback_transportation: '0',
    addback_personal_travel: '0',
    addback_excess_bonuses: '0',
    addback_other_benefit: '0',
  };

  @observable values = {
    year1values: this.defaultValues,
    year2values: this.defaultValues,
    year3values: this.defaultValues,

    addbackValues: this.addbackDefaultValues,
  };

  @action
  getValues(){
    return this.values;
  }

  @action
  setValues(values) {
    this.values = {...values};
  }
}