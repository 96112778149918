import { observable, action } from 'mobx';
import BaseStore from 'stores/BaseStore';
import Numeral from 'numeral';
import request from 'utils/request';

export default class ConfigStore extends BaseStore {
  @observable graphTypeBarsEnabled = true;
  @observable language = 'en';
  @observable currency = {
    code: 'USD',
    prefix: '$',
    decimalScale: 0,
    thousandSeparator: ',',
    decimalSeparator: '.',
    thousandsGroupStyle: 'thousand',
    allowNegative: false,
  };
  @observable messages = [
        {'key': 'header_message_1', 'value': 'Calculate your EBITDA (Earnings Before Interest, Taxes, Depreciation, and Amortization):'},
        {'key': 'content_page_description_1', 'value': 'Earnings Before Interest Taxes Depreciation and Amortization (EBITDA) is a measure of profitability that excludes expense items that are determined by your financing strategies (interest) and tax strategies (taxes, depreciation, and amortization).'},
        {'key': 'header_tab_1', 'value': 'Last Year'},
        {'key': 'header_tab_last_year', 'value': 'Last Year\'s EBITDA'},
        {'key': 'header_tab_2', 'value': '2 Years Ago'},
        {'key': 'header_tab_3', 'value': '3 Years Ago'},

        {'key': 'year_1_page_description', 'value': 'Let\'s figure out what your EBITDA was last year.'},
        {'key': 'year_1_pre_tax_profit_year_label', 'value': 'What was your Pre-Tax Profit last year?'},

        {'key': 'year_2_page_description', 'value': 'Let\'s figure out what your EBITDA was 2 years ago.'},
        {'key': 'year_2_pre_tax_profit_year_label', 'value': 'What was your Pre-Tax Profit 2 years ago?'},

        {'key': 'year_3_page_description', 'value': 'Let\'s figure out what your EBITDA was 3 years ago.'},
        {'key': 'year_3_pre_tax_profit_year_label', 'value': 'What was your Pre-Tax Profit 3 years ago?'},

        {'key': 'interest_year_label', 'value': 'How much interest did you pay?'},
        {'key': 'corporate_income_tax_year_label', 'value': 'How much corporate income tax did you pay?'},
        {'key': 'depreciation_year_label', 'value': 'How much depreciation did you account for?'},
        {'key': 'amortization_year_label', 'value': 'How much amortization did you account for?'},

        {'key': 'summary_title_profit', 'value': 'Pre-tax Profit (Or Loss)'},
        {'key': 'summary_title_interest_payments', 'value': 'Interest Costs'},
        {'key': 'summary_title_taxes', 'value': 'Taxes (Corporate)'},
        {'key': 'summary_title_deprecation', 'value': 'Depreciation'},
        {'key': 'summary_title_amortization', 'value': 'Amortization'},
        {'key': 'summary_title_editda', 'value': 'Adjusted EBITDA'},

        {'key': 'graph_legend_profit', 'value': 'Profit'},
        {'key': 'graph_legend_interest', 'value': 'Interest'},
        {'key': 'graph_legend_taxes', 'value': 'Taxes'},
        {'key': 'graph_legend_deprecation', 'value': 'Depreciation'},
        {'key': 'graph_legend_amortization', 'value': 'Amortization'},

        {'key': 'graph_line_x_year_1', 'value': 'Last Year'},
        {'key': 'graph_line_x_year_2', 'value': '2 Years Ago'},
        {'key': 'graph_line_x_year_3', 'value': '3 Years Ago'},

        {'key': 'summary_title_year_1', 'value': 'Last Year'},
        {'key': 'summary_title_year_2', 'value': '2 Years Ago'},
        {'key': 'summary_title_year_3', 'value': '3 Years Ago'},

        {'key': 'footer_call_to_action', 'value': ''},

        {'key': 'calculation_type', 'value': 1}, //default const TYPE_ONE_YEAR = 1;
        {'key': 'currency_code', 'value': 'USD'},
        {'key': 'currency_prefix', 'value': '$'},
        {'key': 'currency_thousand_separator', 'value': ','},
        {'key': 'header_addback', 'value': 'Addback Owner Perks'},
        {'key': 'addback_page_description', 'value': 'It is common for small business owners to include certain personal expenses on their company′s Profit and Loss statement. These expenses are not necessarily (or exclusively) used to operate the business, but are perks that benefit you personally and will not be necessary for a new owner or employee. Adding these back is necessary to normalize your EBITDA.'},

        {'key': 'addback_real_estate_label', 'value': 'Real Estate (e.g. vacation home, rental property)'},
        {'key': 'addback_transportation_label', 'value': 'Transportation (e.g. car, boat, RV)'},
        {'key': 'addback_personal_travel_label', 'value': 'Personal Travel and Entertainment'},
        {'key': 'addback_excess_bonuses_label', 'value': 'Excess Compensation'},
        {'key': 'addback_other_benefit_label', 'value': 'Value of Any Other Benefits'},
        {'key': 'summary_title_addback', 'value': 'Addbacks'},
        {'key': 'graph_addback', 'value': 'Addbacks'},
  ];

  @action
  formatNumberValue(value, short = true) {
    value = value > 0 ? value : 0;
    if (true === short) {
      // Numeral doesnt work with ₴0,0
      if (value < 10000) {
        return ''+this.currency.prefix+Numeral(value).format('0'+this.currency.thousandSeparator+'0');
      } else if (value < 1000000) {
        return ''+this.currency.prefix+Numeral(value).format('0a');
      } else if (value < 100000000) {
        return ''+this.currency.prefix+Numeral(value).format('0'+this.currency.decimalSeparator+'00a');
      } else {
        return ''+this.currency.prefix+Numeral(value).format('0a');
      }
    } else {
      return ''+this.currency.prefix+Numeral(value).format('0'+this.currency.thousandSeparator+'0');
    }
  }

  @action
  setBarsEnabled(enabled) {
    this.graphTypeBarsEnabled = enabled;
  }

  @action
  isBarsEnabled() {
    return this.graphTypeBarsEnabled === false ? false : true;
  }

  @action
  getMessage(key) {
    const item = this.messages.find((element) => element.key == key);
    return item ? item.value : '~~'+key+'~~';
  }

  @action
  getAddbackTotalValue(values) {
    return (
      values.addback_real_estate*1
      +values.addback_transportation*1
      +values.addback_personal_travel*1
      +values.addback_excess_bonuses*1
      +values.addback_other_benefit*1
    );
  }

  @action
  fetchMessages(appConfig, statusKey = 'boot') {
    const status = this.createStatus(statusKey);

    return request({
        path: '/ebitda/ajax/',
        params: { advisorId: (appConfig.advisorId || null), language: this.language }
      }).then(({ messages }) => {
        status.success();
        this.messages.replace(messages);

        if (messages) {
          messages.forEach(function(element) {
            if (element.key === 'currency_code') {
              this.currency.code = element.value;
            } else if (element.key === 'currency_prefix') {
              this.currency.prefix = element.value;
            } else if (element.key === 'currency_thousand_separator') {
              this.currency.thousandSeparator = element.value;
            }
          }, this);
        }

        return messages;
      }).catch((error) => {
        status.success();

        return this.messages;

        // status.error(error);
        //
        // return error;
    });
  }
}