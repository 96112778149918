import React from 'react';
import {Grid, Tab, GridRow} from 'semantic-ui-react';
import {inject} from "mobx-react";
import EbitdaForm from "./EbitdaForm";
import AddbackForm from "./AddbackForm";
import Summary from "./Summary";
import EbitdaGraph from "./EbitdaGraph";

@inject('config')
export default class Year extends React.Component {
  constructor(props) {
    super(props);
    this.handleFormUpdate = this.handleFormUpdate.bind(this);
    this.yearNumber = 1;
  }

  handleFormUpdate(values) {
    this.props.onFormUpdate(values);
  }

  getTitle() {
    const { yearNumber, config } = this.props;
    switch (yearNumber) {
      case 1:
        return config.getMessage('year_1_page_description');
      case 2:
        return config.getMessage('year_2_page_description');
      case 3:
        return config.getMessage('year_3_page_description');
      case 'addback':
        return config.getMessage('addback_page_description');
    }
  }

  render() {
    const { values, yearNumber } = this.props;

    return (
      <Tab.Pane attached={false}>
        <div className="container-year-1 container-year">
          <Grid devided="vertically">
            <Grid.Row columns={2}>
              <Grid.Column>
                <p class="year-title">{ this.getTitle() }</p>
                {yearNumber === 'addback' && (
                  <AddbackForm formValues={values} onEbitdaFormChange={this.handleFormUpdate}></AddbackForm>
                )}
                {yearNumber !== 'addback' && (
                  <EbitdaForm formValues={values} yearNumber={yearNumber} onEbitdaFormChange={this.handleFormUpdate}></EbitdaForm>
                )}
              </Grid.Column>
              <Grid.Column id="second-row">
                <GridRow>
                  <Summary></Summary>
                </GridRow>
                <GridRow>
                  <EbitdaGraph></EbitdaGraph>
                </GridRow>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Tab.Pane>
    )
  }
}