import React from 'react';
import { observer, inject } from 'mobx-react';
import AppWrapper from 'components/AppWrapper';
import {
  Container,
  Dimmer,
  Header,
  Tab,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react';
import Year from "../../components/Year";
import PageCenter from '../../components/PageCenter';

@inject('config', 'years')
@observer
export default class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.handleYear1FormUpdate = this.handleYear1FormUpdate.bind(this);
    this.handleYear2FormUpdate = this.handleYear2FormUpdate.bind(this);
    this.handleYear3FormUpdate = this.handleYear3FormUpdate.bind(this);
    this.handleAddbackYearFormUpdate = this.handleAddbackYearFormUpdate.bind(this);
    this.config = [];
    this.appConfig = props.appConfig || {};
  }

  handleYear1FormUpdate(newValues) {
    const { values } = this.props.years;
    this.props.years.setValues(
    {
      ...values,
      'year1values': newValues
    });
  }
  handleYear2FormUpdate(newValues) {
    const { values } = this.props.years;
    this.props.years.setValues(
      {
        ...values,
        'year2values': newValues
      });
  }
  handleYear3FormUpdate(newValues) {
    const { values } = this.props.years;
    this.props.years.setValues(
      {
        ...values,
        'year3values': newValues
      });
  }

  handleAddbackYearFormUpdate(newValues) {
    const { values } = this.props.years;
    this.props.years.setValues(
      {
        ...values,
        'addbackValues': newValues
      });
  }


  componentDidMount() {
    this.props.config.fetchMessages(this.appConfig);
  }

  render() {
    const { config } = this.props;
    const { year1values, year2values, year3values, addbackValues } = this.props.years.values;
    const configStatus = config.getStatus('boot');
    var panes = [];

    if (config.getMessage('calculation_type') === 1) { // one year
      panes = [
        { menuItem: config.getMessage('header_tab_last_year'), render: () => <Year values={year1values} yearNumber={1} onFormUpdate={this.handleYear1FormUpdate}></Year> },
        { menuItem: config.getMessage('header_addback'), render: () => <Year values={addbackValues} yearNumber={'addback'} onFormUpdate={this.handleAddbackYearFormUpdate}></Year> },
      ];
    } else {
      panes = [
        { menuItem: config.getMessage('header_tab_1'), render: () => <Year values={year1values} yearNumber={1} onFormUpdate={this.handleYear1FormUpdate}></Year> },
        { menuItem: config.getMessage('header_tab_2'), render: () => <Year values={year2values} yearNumber={2} onFormUpdate={this.handleYear2FormUpdate}></Year> },
        { menuItem: config.getMessage('header_tab_3'), render: () => <Year values={year3values} yearNumber={3} onFormUpdate={this.handleYear3FormUpdate}></Year> },
      ];
    }

    return (
      <AppWrapper>
        {configStatus.success && (
          <Container>
            <Header as='h2' className="page-heading">{config.getMessage('header_message_1')}</Header>
            <p className="page-description">{config.getMessage('content_page_description_1')}</p>
            <div className="calculator-container">
              <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
            </div>
            <div className="footer-call-to-action" dangerouslySetInnerHTML={{__html: config.getMessage('footer_call_to_action') }}></div>
          </Container>
        )}
        {configStatus.request && (
          <Container>
            <Segment style={{ height: '100px' }}>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            </Segment>
          </Container>
        )}
        {configStatus.error && (
          <Container>
            <Message error content={configStatus.error.message} />
          </Container>
        )}
      </AppWrapper>
    );
  }
}
