import React from 'react';
import {inject} from "mobx-react";
import {List, Table} from "semantic-ui-react";

@inject('config', 'years')
export default class Summary extends React.Component {
  constructor(props) {
    super(props);
    this.formValues = {};
  }

  getTotalValue(values) {
    return (
      values.pre_tax_profit_year*1
      +values.interest_year*1
      +values.corporate_income_tax_year*1
      +values.depreciation_year*1
      +values.amortization_year*1
    );
  }

  render() {
    const { config } = this.props;
    const { year1values, year2values, year3values, addbackValues } = this.props.years.values;

    if (config.getMessage('calculation_type') === 1) { // one year
      return (
        <div className="summary">
          <Table definition>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                <Table.HeaderCell>{ config.getMessage('summary_title_year_1') }</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{ config.getMessage('summary_title_profit') }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year1values.pre_tax_profit_year) }</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{ config.getMessage('summary_title_interest_payments') }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year1values.interest_year) }</Table.Cell>
              </Table.Row>
              {/*<Table.Row>*/}
              {/*  <Table.Cell>{ config.getMessage('summary_title_taxes') }</Table.Cell>*/}
              {/*  <Table.Cell>{ config.formatNumberValue(year1values.corporate_income_tax_year) }</Table.Cell>*/}
              {/*</Table.Row>*/}
              <Table.Row>
                <Table.Cell>{ config.getMessage('summary_title_deprecation') }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year1values.depreciation_year) }</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{ config.getMessage('summary_title_amortization') }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year1values.amortization_year) }</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{ config.getMessage('summary_title_addback') }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(config.getAddbackTotalValue(addbackValues)) }</Table.Cell>
              </Table.Row>
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>{ config.getMessage('summary_title_editda') }</Table.HeaderCell>
                <Table.HeaderCell>{ config.formatNumberValue(this.getTotalValue(year1values) + config.getAddbackTotalValue(addbackValues)) }</Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      );

    } else {
      return (
        <div className="summary">
          <Table definition>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>{ config.getMessage('summary_title_year_1') }</Table.HeaderCell>
                <Table.HeaderCell>{ config.getMessage('summary_title_year_2') }</Table.HeaderCell>
                <Table.HeaderCell>{ config.getMessage('summary_title_year_3') }</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{ config.getMessage('summary_title_profit') }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year1values.pre_tax_profit_year) }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year2values.pre_tax_profit_year) }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year3values.pre_tax_profit_year) }</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{ config.getMessage('summary_title_interest_payments') }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year1values.interest_year) }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year2values.interest_year) }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year3values.interest_year) }</Table.Cell>
              </Table.Row>
              {/*<Table.Row>*/}
              {/*  <Table.Cell>{ config.getMessage('summary_title_taxes') }</Table.Cell>*/}
              {/*  <Table.Cell>{ config.formatNumberValue(year1values.corporate_income_tax_year) }</Table.Cell>*/}
              {/*  <Table.Cell>{ config.formatNumberValue(year2values.corporate_income_tax_year) }</Table.Cell>*/}
              {/*  <Table.Cell>{ config.formatNumberValue(year3values.corporate_income_tax_year) }</Table.Cell>*/}
              {/*</Table.Row>*/}
              <Table.Row>
                <Table.Cell>{ config.getMessage('summary_title_deprecation') }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year1values.depreciation_year) }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year2values.depreciation_year) }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year3values.depreciation_year) }</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{ config.getMessage('summary_title_amortization') }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year1values.amortization_year) }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year2values.amortization_year) }</Table.Cell>
                <Table.Cell>{ config.formatNumberValue(year3values.amortization_year) }</Table.Cell>
              </Table.Row>
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>{ config.getMessage('summary_title_editda') }</Table.HeaderCell>
                <Table.HeaderCell>{ config.formatNumberValue(this.getTotalValue(year1values)) }</Table.HeaderCell>
                <Table.HeaderCell>{ config.formatNumberValue(this.getTotalValue(year2values)) }</Table.HeaderCell>
                <Table.HeaderCell>{ config.formatNumberValue(this.getTotalValue(year3values)) }</Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      );
    }
  }
}